<template>
<div>
  <div id="navigation" v-if="true">
    <div class="logo">
      <img src="~@/assets/excelerate-symbol.png" class="symbol">
    </div> 
    <nav class="navigation-routes" v-if="user">
      <router-link to="/events" v-if="userRole === 'student'"><i class="fas fa-calendar-alt"></i> <span>Events</span></router-link>
      <router-link to="/dashboard"><i class="far fa-star"></i> <span>Dashboard</span></router-link>
      <router-link to="/collaboration/my" v-if="userRole === 'student'"><i class="fas fa-hands-helping"></i> <span>My Thesis Topics</span></router-link>
      <router-link :to="'/companies/' + user.company" v-if="userRole === 'professional' && user.company"><i class="fas fa-user-tie"></i> <span>Your company</span></router-link>
      <router-link to="/company/new" v-if="userRole === 'professional' && !user.company"><i class="fas fa-plus"></i> <span>Register company</span></router-link>
      <router-link to="/collaborations" v-if="userRole === 'professional' && user.company"><i class="fas fa-search"></i> <span>Find Thesis Topics</span></router-link>
      <router-link to="/collaborations" v-if="userRole === 'student'"><i class="fas fa-search"></i> <span>Browse Thesis Topics</span></router-link>
      <!-- <router-link to="/profile"><i class="fas fa-user"></i> <span>user profile</span></router-link> -->
    </nav>
  </div>
  <div v-else class="thesis-profile">
     <div class="logo">
      <img src="~@/assets/excelerate-symbol.png" class="symbol">
    </div> 
  </div> 
</div>
</template>

<script>
import {mapState} from 'vuex'
import { base64ToString } from '../utils/utils'

let role = base64ToString(localStorage.getItem('i_am'))

export default {

    name: 'AppNavigation',
    data(){
      return {
      }
    },

    computed: {
      user(){
        if(role === 'student')return this.$store.getters.studentData
        if(role === 'professional')return this.$store.getters.professionalData
        return []
      },
      userRole(){
        return role;
      }
    },
  methods: {

  }
}
</script>
<style scoped>
.thesis-profile .logo .symbol {
    margin: 20px 0 0 15px;

}

</style>
