<template>
<div id="app-master" >

    <AppNavigation />

  <div id="page">
    <main>
      <router-view />
    </main>
  </div>

  <!-- <div class="container">
      <small>{{ user }}</small>

  </div> -->
    <MasterFooter />

</div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import {mapState} from 'vuex'

import AppNavigation from '@/components/AppNavigation.vue'
import MasterFooter from '@/components/MasterFooter.vue'
import apiInstance from '../axios/axios';
import { base64ToString } from '../utils/utils';

let userId = localStorage.getItem('uid')
userId = base64ToString(userId)
let tok = localStorage.getItem('i_am')
tok = base64ToString(tok)
let userRole = localStorage.getItem('auth-token')
userRole = base64ToString(userRole)

export default {
  name: 'AppMaster',
  components: {
    AppNavigation,
    MasterFooter,
  },
  data: function() {
    return {
      navIsShown: false,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    if(userRole === 'student' && !this.$store.getters.studentData) {
      this.$store.dispatch('getStudentData', userId);
    }else if (userRole === 'professional' && !this.$store.getters.professionalData) {
      this.$store.dispatch('getProfessionalData', userId);
    }
    if(userRole === 'student' && this.$store.getters.userCollaborations && this.$store.getters.userCollaborations.length === 0){
      this.$store.dispatch('getAllUserCollaborations', userId)
    }
    // if (!this.$store.getters.studentContent || !this.$store.getters.professionalContent) {
    //   this.$store.dispatch('getStudentContent')
    //   this.$store.dispatch('getProfessionalContent')
    // }
  },
  methods: {
    // testMe: function() {
    //   const currentUser = this.$store.state.user;
    //   if(currentUser.firstName !== null) {
    //     if(currentUser.firstName.length === 0) {
    //       this.$store.dispatch("sendUserToWelcomeFlow");
    //     }
    //   }


    // }
  }
}
</script>

<style lang="scss" src="@/assets/scss/style.scss"></style>
